<template>
<!-- assessmentName 위험성평가 시나리오 목록 -->
  <c-table
    ref="table"
    :title="$comm.getLangLabel('LBL0001153', {s1: assessmentName})"
    :columns="gridColumns"
    :data="grid.data"
    :merge="gridMerge"
    :gridHeight="grid.height"
    @changeSelection="changeSelection"
  >
    <template v-slot:customArea="{ props, col }">
      <template v-if="col.name==='customCol'">
        <component
          :is="imprComponent"
          :col="col"
          :props="props"
          :inputEditable="false"
          :requestContentsCols="imprProps.requestContentsCols"
          :tableKey="imprProps.tableKey"
          :ibmTaskTypeCd="imprProps.ibmTaskTypeCd"
          :ibmTaskUnderTypeCd="imprProps.ibmTaskUnderTypeCd"
        />
      </template>
      <template v-else-if="col.name==='ramRiskLevelName'">
        <q-chip text-color="white" :style="`background-color:${props.row.riskColor};color:white;`">
          {{props.row[col.name]}}
        </q-chip>
      </template>
    </template>
  </c-table>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import reCheckMixin from './reCheckMixin'
export default {
  name: 'reCheckSecnario',
  mixins: [reCheckMixin],
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          ramRiskAssessmentPlanId: '',
          ramTechniqueCd: '',
        }
      },
    },
    assessmentName: {
      type: String,
      default: '',
    },
    ramRiskAssessmentPlanId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      grid: {
        height: '550px',
        data: [],
      },
      imprComponent: () => import(`${'@/pages/common/ibm/tableImpr.vue'}`),
      editable: true,
      selectRisks: [],
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    ramRiskAssessmentPlanId: {
      handler: function () {
        this.getList()
      },
      deep: true,
    },
  },
  computed: {
    listUrl() {
      let url = '';
      if (this.popupParam.ramTechniqueCd === 'RT00000001') {
        // HAZOP 아직..
        url = selectConfig.ram.hazop.scenario.list.url
      } else if (this.popupParam.ramTechniqueCd === 'RT00000005') {
        // K-PSR
        url = selectConfig.ram.kpsr.scenario.list.url
      } else if (this.popupParam.ramTechniqueCd === 'RT00000010') {
        // Check-list 아직..
        url = selectConfig.ram.checklist.scenario.list.url
      } else if (this.popupParam.ramTechniqueCd === 'RT00000015') {
        // JRA
        url = selectConfig.ram.jsa.scenario.list.url
      } else if (this.popupParam.ramTechniqueCd === 'RT00000020') {
        // KRAS
        url = selectConfig.ram.kras.scenario.list.url
      } else if (this.popupParam.ramTechniqueCd === 'RT00000025') {
        // 4M
        url = selectConfig.ram.fm.scenario.list.url
      } else if (this.popupParam.ramTechniqueCd === 'RT00000030') {
        // CHARM 아직..
        url = selectConfig.ram.charm.scenario.result.url
      } else if (this.popupParam.ramTechniqueCd === 'RT00000035') {
        // 3단계 판단법
        url = selectConfig.ram.third.scenario.list.url
      }
      return url;
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      // 선택된 위험성평가 정보가 있는 경우
      if (this.ramRiskAssessmentPlanId) {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          ramRiskAssessmentPlanId: this.ramRiskAssessmentPlanId,
          ramTechniqueCd: this.popupParam.ramTechniqueCd,
        };
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    changeSelection() {
      let selectData = this.$refs['table'].selected;
      this.$set(this.$data, 'selectRisks', selectData)
    },
  }
};
</script>
