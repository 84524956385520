var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-table", {
    ref: "table",
    attrs: {
      title: _vm.$comm.getLangLabel("LBL0001153", { s1: _vm.assessmentName }),
      columns: _vm.gridColumns,
      data: _vm.grid.data,
      merge: _vm.gridMerge,
      gridHeight: _vm.grid.height,
    },
    on: { changeSelection: _vm.changeSelection },
    scopedSlots: _vm._u([
      {
        key: "customArea",
        fn: function ({ props, col }) {
          return [
            col.name === "customCol"
              ? [
                  _c(_vm.imprComponent, {
                    tag: "component",
                    attrs: {
                      col: col,
                      props: props,
                      inputEditable: false,
                      requestContentsCols: _vm.imprProps.requestContentsCols,
                      tableKey: _vm.imprProps.tableKey,
                      ibmTaskTypeCd: _vm.imprProps.ibmTaskTypeCd,
                      ibmTaskUnderTypeCd: _vm.imprProps.ibmTaskUnderTypeCd,
                    },
                  }),
                ]
              : col.name === "ramRiskLevelName"
              ? [
                  _c(
                    "q-chip",
                    {
                      style: `background-color:${props.row.riskColor};color:white;`,
                      attrs: { "text-color": "white" },
                    },
                    [_vm._v(" " + _vm._s(props.row[col.name]) + " ")]
                  ),
                ]
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }